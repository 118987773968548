import React from "react";
import ScrollTo from "react-scroll-into-view";
import styled from "styled-components";

const Nav = styled.nav`
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 11;
`;

const Button = styled.button`
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 900;
  color: #f50057;
  border: 2px solid #f50057;
  cursor: pointer;
  width: max-content;
  transition: 0.25s ease;
  outline: none;
  :hover {
    border-radius: 25px;
  }
`;

const Menu = () => (
  <Nav>
    <ul>
      {Array.from({ length: 5 }, (_, i) => i + 1).map((card) => (
        <li key={card}>
          <ScrollTo selector={`#card${card}`}>
            <Button>{card}</Button>
          </ScrollTo>
        </li>
      ))}
    </ul>
  </Nav>
);

export default Menu;
