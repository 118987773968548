import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2"; // Make sure to import the library
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
// import ".//build/css/intlTelInput.css";
import "./App.scss";
import axios from "axios";
export const Myform = () => {

  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  // Manually track the phone input value
  const phoneValue = watch("phone", "");

  // Format phone number with a space after the country code
  const formatPhoneNumber = (phone) => {
    // Insert a space after the country code (assumes the country code is the first 3 characters)
    const countryCodeLength = phone.charAt(1) === "1" ? 2 : 3; // Adjust country code length for single or double-digit codes
    return (
      phone.slice(0, countryCodeLength + 1) +
      " " +
      phone.slice(countryCodeLength + 1)
    );
  };
  const onSubmit = async (data) => {
    try {
      // Send the data to the backend
      const response = await axios.post(
        "https://backend-1s67.onrender.com/api/form/submit",
        data
      );
      console.log(data);
      console.log(response.data);

      // Set success message
      alert("Details submitted successfully");
    } catch (error) {
      console.error("Error submitting form data", error);

      // Set failure message
      alert("Details submitted successfully");
    }
  };

  return (
    <>
      <Helmet>
        <title>ASK GRAPHIX | Contact-Form</title>
        <meta
          name="description"
          content="Askgraphix | Contact-Form"
        />
      </Helmet>
      <div className="container2 contactForm">
        <div className="left-column slide-right">
          <div className="highlight">
            <div className="text-with-svg">
              <svg
                data-name="Layer 1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21,4H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H3A1,1,0,0,0,2,5V8a4,4,0,0,0,4,4H7.54A6,6,0,0,0,11,13.91V16H10a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19a3,3,0,0,0-3-3H13V13.91A6,6,0,0,0,16.46,12H18a4,4,0,0,0,4-4V5A1,1,0,0,0,21,4ZM6,10A2,2,0,0,1,4,8V6H6V8a6,6,0,0,0,.35,2Zm8,8a1,1,0,0,1,1,1v1H9V19a1,1,0,0,1,1-1ZM16,8A4,4,0,0,1,8,8V4h8Zm4,0a2,2,0,0,1-2,2h-.35A6,6,0,0,0,18,8V6h2Z"
                  fill="black"
                />
              </svg>
              <div className="text-content">
                <h5>20 Years of IT Excellence</h5>
                <p>Delivering successful projects with unwavering commitment.</p>
              </div>
            </div>
          </div>
          <div className="highlight">
            <div className="text-with-svg">
              <svg
                enableBackground="new 0 0 256 256"
                version="1.1"
                viewBox="0 0 256 256"
                x="0px"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                y="0px"
              >
                <metadata>
                  {" "}
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
                </metadata>
                <g>
                  <g>
                    <path
                      d="M201.7,83.8c0-40.7-33-73.8-73.7-73.8c-40.7,0-73.8,33-73.8,73.8c0,16.5,5.6,31.6,14.8,43.9c0,0.1,0,0.2,0,0.3v103.3c0,5.4,3,10.5,7.8,13c4.8,2.6,10.6,2.3,15.1-0.7l36-24.1l36.1,24c2.5,1.6,5.3,2.5,8.2,2.5c2.4,0,4.8-0.6,7-1.7c4.8-2.6,7.8-7.6,7.8-13V128c0-0.1,0-0.2,0-0.3C196.1,115.4,201.7,100.3,201.7,83.8z M128,39.5c24.4,0,44.2,19.8,44.2,44.3c0,24.4-19.8,44.2-44.2,44.2c-24.4,0-44.3-19.8-44.3-44.2C83.8,59.3,103.6,39.5,128,39.5z M136.2,189.5c-5-3.3-11.4-3.3-16.4,0l-21.3,14.2v-52.4c9.1,3.9,19,6.2,29.5,6.2c10.5,0,20.4-2.3,29.5-6.2v52.4L136.2,189.5z"
                      fill="black"
                    />
                  </g>
                </g>
              </svg>
              <div className="text-content">
                <h5>Project Management Office</h5>
                <p>Ensuring top-tier service quality while reducing project costs.</p>
              </div>
            </div>
          </div>
          <div className="highlight">
            <div className="text-with-svg">
              <svg
                fill="#000000"
                height="800px"
                viewBox="0 0 64 64"
                width="800px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title />
                <g data-name="Layer 13" id="Layer_13">
                  <path
                    d="M57,11H42V8.06A5.07,5.07,0,0,0,36.94,3H27.06A5.07,5.07,0,0,0,22,8.06V11H7a4,4,0,0,0-4,4V30a5,5,0,0,0,2,4V55a6,6,0,0,0,6,6H53a6,6,0,0,0,6-6V34a5,5,0,0,0,2-4V15A4,4,0,0,0,57,11ZM24,8.06A3.06,3.06,0,0,1,27.06,5h9.88A3.06,3.06,0,0,1,40,8.06V11H24ZM57,35V55a4,4,0,0,1-4,4H11a4,4,0,0,1-4-4V35h4v5a3,3,0,0,0,6,0V35H29v2a3,3,0,0,0,6,0V35H47v5a3,3,0,0,0,6,0V35h4ZM13,40V30a1,1,0,0,1,2,0V40a1,1,0,0,1-2,0Zm18-3V33a1,1,0,0,1,2,0v4a1,1,0,0,1-2,0Zm18,3V30a1,1,0,0,1,2,0V40a1,1,0,0,1-2,0ZM59,30a3,3,0,0,1-3,3H53V30a3,3,0,0,0-6,0v3H35a3,3,0,0,0-6,0H17V30a3,3,0,0,0-6,0v3H8a3,3,0,0,1-3-3V15a2,2,0,0,1,2-2H57a2,2,0,0,1,2,2Z"
                    fill="black"
                  />
                </g>
              </svg>
              <div className="text-content">
                <h5>400+ Success Stories</h5>
                <p>Trusted by leading companies for exceptional project delivery.</p>
              </div>
            </div>
          </div>
          <div className="highlight">
            <div className="text-with-svg">
              <svg
                fill="#000000"
                height="800px"
                viewBox="0 0 56 56"
                width="800px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 38.7232 28.5490 C 43.1399 28.5490 46.9403 24.6047 46.9403 19.4690 C 46.9403 14.3949 43.1193 10.6356 38.7232 10.6356 C 34.3271 10.6356 30.5061 14.4771 30.5061 19.5101 C 30.5061 24.6047 34.3066 28.5490 38.7232 28.5490 Z M 15.0784 29.0215 C 18.8994 29.0215 22.2274 25.5703 22.2274 21.1125 C 22.2274 16.6958 18.8789 13.4294 15.0784 13.4294 C 11.2575 13.4294 7.8885 16.7779 7.9090 21.1536 C 7.9090 25.5703 11.2370 29.0215 15.0784 29.0215 Z M 3.6155 47.5717 L 19.2281 47.5717 C 17.0917 44.4697 19.7006 38.2247 24.1173 34.8146 C 21.8371 33.2944 18.8994 32.1645 15.0579 32.1645 C 5.7931 32.1645 0 39.0053 0 44.6957 C 0 46.5445 1.0271 47.5717 3.6155 47.5717 Z M 25.8018 47.5717 L 51.6241 47.5717 C 54.8493 47.5717 56 46.6472 56 44.8395 C 56 39.5394 49.3644 32.2261 38.7026 32.2261 C 28.0616 32.2261 21.4262 39.5394 21.4262 44.8395 C 21.4262 46.6472 22.5766 47.5717 25.8018 47.5717 Z"
                  fill="black"
                />
              </svg>
              <div className="text-content">
                <h5>A Team of Passionate Experts</h5>
                <p>Over 10 specialists, dedicated to what they love and do best.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="center-columns slide-top">
          <img
            alt="ContactUs"
            className="image"
            src="./Contact-us.jpeg"
          />
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group textarea">
              <textarea
                {...register("description", {
                  required: "Description is required",
                })}
                placeholder="Kindly describe your request — we usually respond within 1 hour on business days."
              />
              {errors.description && (
                <p className="error-message">{errors.description.message}</p>
              )}
            </div>
            <div className="form-row">
              <div className="form-group">
                <input
                  {...register("Fullname", {
                    required: "Full name is required",
                  })}
                  placeholder="Full name"
                  type="text"
                />
                {errors.Fullname && (
                  <p className="error-message">{errors.Fullname.message}</p>
                )}
              </div>
              <div className="form-group">
                <input
                  {...register("Company", {
                    required: "Company name is required",
                  })}
                  placeholder="Company"
                  type="text"
                />
                {errors.Company && (
                  <p className="error-message">{errors.Company.message}</p>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <input
                  {...register("Workemail", {
                    required: "Work email is required",
                  })}
                  placeholder="Work email"
                  type="email"
                />
                {errors.Workemail && (
                  <p className="error-message">{errors.Workemail.message}</p>
                )}
              </div>

              <div className="form-group">
                <PhoneInput
                  country={"in"}
                  value={phoneValue}
                  onChange={(phone) =>
                    setValue("phone", formatPhoneNumber(phone))
                  }
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {errors.phone && (
                  <p className="error-message">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <button onclick="dataLayer.push({'buttonName': 'Submit Button'});" className="cta-button font-rr22-1" type="submit">Submit</button>
          </form>
          <img src="./trans.gif?event=form_submitted" width="1" height="1" alt="tracking pixel" style={{display:"none"}} />

        </div>
        
      </div>
    </>
  );
};
export default Myform;
