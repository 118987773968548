const workData = [
  {
    id: 1,
    color: "#797979",
    image: "/logo/havas.jpeg",
    image2: "/work/havas.jpg",
    title: "HAVAS GROUP",
    role: "#Wordpress, #HTML, #SCSS, #Javascript, #Invision, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    headPara: "https://kx.havas.com/",
    text: "Havas SA is a French multinational advertising and public relations company, headquartered in Paris, France. It operates in more than 100 countries and is one of the largest advertising and communications groups in the world. I've maintained the frontend part for all 18 websites of our Havas local company, some of them are these... https://havascreative.com/ , https://havascx.com/ , https://havasformula.com/",
  },
  {
    id: 2,
    color: "#cb0200",
    image: "/logo/canon.png",
    image2: "/work/canon.jpg",
    title: "Canon - SNAPSHOT",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    headPara: "https://snapshot.canon-asia.com/",
    text: "Snapshot is a content portal produced and managed by Canon, with user base comprising mostly of professional photographers and photography enthusiasts. They called for a pitch presentation among agencies in the 3rd quarter of 2015. At that time, the website is in bad need of a re-design. The user experience is disjointed and finding content is counter-intuitive. It also needs an over-all restructuring of content architecture. The whole back-end system is also up for a whole revamp to enable better CMS and there’s a strong need to rebuild a robust backend development framework.",
  },
  {
    id: 3,
    color: "#221e20",
    image: "/logo/arnold-logo.png",
    image2: "/work/arnold.png",
    title: "arnold",
    role: "#Wordpress, #HTML, #SCSS, #Javascript, #XD, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "Arnold Worldwide is an American advertising agency headquartered in Boston, Massachusetts. The agency is part of Havas, a global advertising holding company based in France. Some of Arnold's clients include Progressive Insurance, Cox Communications, and AmeriSave.",
    headPara: "https://arn.com/",
  },
  {
    id: 4,
    color: "#386873",
    image: "/logo/eandiengineers-logo-sm.png",
    image2: "/work/eandiengineers.jpeg",
    title: "E AND I Engineers",
    role: "#REACT-JS, #HTML, #SCSS, #Javascript, #Email Marketing, #Digital Marketing",
    text: "At E&I Engineers dedicated to delivering top-notch electrical and instrumentation engineering solutions with a strong focus on quality, safety, and reliability. Our comprehensive services address a wide range of industrial and infrastructural needs, including electrical system design, project management, erection, installation and commissioning, and maintenance and support. Conduct safety audits and ensure compliance with regulations, provide renewable energy solutions, design power distribution and control systems, and offer industrial automation and consulting services.",
    headPara: "https://eandiengineers.com",
  },
  {
    id: 5,
    color: "#04362a",
    image: "/logo/skoda.png",
    image2: "/askgraphix.png",
    title: "skoda",
    role: "#React, #HTML, #SCSS, #Javascript, #XD, #Animation/effects, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "Skoda, is a Czech automobile manufacturer established in 1925 as the successor to Laurin & Klement and headquartered in Mladá Boleslav, Czech Republic. Skoda Works became state owned in 1948.",
    headPara: "https://www.skoda.fr/",
  },
  {
    id: 6,
    color: "#122d61",
    image: "/logo/subaru.jpeg",
    image2: "/work/Subaru.png",
    title: "Subaru",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    headPara: "https://www.subaru.com/",
    text: "Subaru also offers turbocharged versions of their passenger cars, such as the WRX, Legacy and Outback XT, Ascent, and formerly the Legacy GT and Forester XT.",
  },
  {
    id: 7,
    color: "#00184f",
    image: "/logo/Volkswagen.png",
    image2: "/askgraphix.png",
    title: "Volkswagen",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    headPara: "https://www.volkswagen.fr/",
    text: "Volkswagen AG, known internationally as the Volkswagen Group, is a German multinational automotive manufacturer headquartered in Wolfsburg, Lower Saxony, Germany.",
  },
  {
    id: 8,
    color: "#7f036e",
    image: "/logo/Vivendi.png",
    image2: "/work/vivendi.jpg",
    title: "Vivendi",
    role: "#Wordpress, #HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    headPara: "https://www.vivendi.com/en/",
    text: "Vivendi brings together an exceptional array of talent in any form of artistic expression and industry professionals providing creative and emotionally-engaging entertainment to audiences around the globe. Work closely to foster culture in all its diversity. Vivendi help to share knowledge and actively defend the values of equality and inclusion through all our activities. Vivendi make a meaningful difference to brands. Vivendi is home to some of the most prestigious brands in television and cinema, communication, publishing, magazines, videogames, live entertainment and distribution platforms.",
  },

  {
    id: 9,
    color: "#00519f",
    image: "/logo/FairPrice_logo.png",
    image2: "/work/fairPrice.jpg",
    title: "FairPrice",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "The everyday stories of people make up the fabric of modern Singapore. These people from all walks of life are the teachers, the grocer, the architects, the builders, the neighbourhood uncle serving coffee at a nearby Kopitiam. They are also the unsung heroes that continue to hold the fabric of modern Singapore. It is this big idea that #fortheheroesinourlives campaign was born.Havas Media's Content & Strategy team commissioned our team, the Havas Media Design + Tech team to work on this campaign. The Big Idea revolves around the concept of the “SG 50 Cake” as the visual symbol and element central to the campaign.",
    headPara: "https://www.fairprice.com.sg/",
  },
  {
    id: 10,
    color: "#007fb5",
    image: "/logo/SC.jpg",
    image2: "/work/sc.jpg",
    title: "Standard Chartered Digital Queue",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "The Standard Chartered Marathon Digital Queue was launched by Socialyse, Havas Media’s social media team, Socialyse in 2015. This social media-powered campaign received positive reviews and the client wanted to run the same campaign for 2016. Our team was not tapped to work on the project in 2015, and they want to improve the whole user experience as well as user interface for the 2016 digital campaign. For the mechanics, participants will be given a daily task for the whole campaign period of 7 days. Each task have a corresponding number of points and sharing their posts via Twitter, Facebook and Instagram will earn them additional points. Likes from their friends and followers would also earn additional points. Participants who ranked in the top 100 in the website’s digital queue will run the 2016 Standard Chartered Marathon free of charge. Additional prizes would also be given to the top 3 participants.",
    headPara:
      "http://www.crayonslife.gallery/standard-chartered-marathon-digital-queue",
  },
  {
    id: 11,
    color: "#4e2545",
    image: "/logo/Clarks-logo.jpeg",
    image2: "/work/clarks.jpg",
    title: "Clarks",
    role: "#HTML, #SCSS, #Javascript, #Animation/effects, #Invision, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "C. & J. Clark International Ltd, doing business as Clarks, is a British international shoe manufacturer and retailer. It was founded in 1825 by Cyrus Clark in the village of Street, Somerset, England, where the company's headquarters remain.",
    headPara: "Clarks",
  },
  {
    id: 12,
    color: "#ed1c25",
    image: "/logo/conon-photo-marathon.jpeg",
    image2: "/work/conon-photo-marathon.jpg",
    title: "conon-photo-marathon",
    role: "#HTML, #SCSS, #Javascript, #Photoshop/Invision, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "Their previous agency failed to deliver a CMS that can be used by all country admin for the Canon PhotoMarathon. This resulted to a disjointed user experience and user interface across participating countries in the region. The Canon PhotoMarathon event was a brainchild of the Canon Singapore Team. It’s a whole day event where both photography enthusiast and professionals take the grueling challenge of shooting photos with 4 different themes within a timeline. At the end of the day, winners will be declared for different categories. The Grand Winner will have the chance to participate in an all-paid expense trip to the PHOTO CLINIC usually held in picturesque places. The PHOTO CLINIC gathers all the winners from different regions where photography workshops are held by well-known Canon Photographers.",
    headPara: "http://www.crayonslife.gallery/#/canon-photomarathon/",
  },
  {
    id: 13,
    color: "#afafaf",
    image: "/logo/Hermes-logo.jpeg",
    image2: "/work/hermes.jpg",
    title: "Hermes",
    role: "Timeline, standup meeting, write down task as per due dates, Create a To-Do List for Each Week or Each Day",
    text: "#Banner-production, #Email-marketing",
    headPara: "https://www.hermes.com/",
  },
  {
    id: 14,
    color: "#6f583d",
    image: "/logo/smu-logo.jpeg",
    image2: "/work/smu.jpg",
    title: "SMU",
    role: "#Drupal, #HTML, #SCSS, #Javascript, #Animation/effects, #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "A premier university in Asia, the Singapore Management University (SMU) is internationally recognised for its world-class research and distinguished teaching. Established in 2000, SMU's mission is to generate leading-edge research with global impact and to produce broad-based, creative, and entrepreneurial leaders for the knowledge-based economy. SMU's education is known for its highly interactive, collaborative, and project-based approach to learning. Home to over 12,000 students across undergraduate, postgraduate professional and postgraduate research programmes, SMU comprises eight schools",
    headPara: "https://smuseum.smu.edu.sg/",
  },

  {
    id: 15,
    color: "#d00427",
    image: "/logo/Huawei-logo.png",
    image2: "/work/huawei.jpg",
    title: "Huawei",
    role: "#HTML, #SCSS, #Javascript, #Photoshop, #Animation/effects, #Photoshop, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "Huawei Technologies Co., Ltd. is a Chinese multinational technology corporation headquartered in Shenzhen, Guangdong province, that designs, develops, manufactures and sells telecommunications equipment, consumer electronics, smart devices and various rooftop solar power products.",
    headPara: "https://www.huawei.com/en/",
  },
  {
    id: 16,
    color: "#1c325c",
    image: "/logo/flinders_university_logo.png",
    image2: "/work/Flinders_University.jpg",
    title: "Flinders university",
    role: "#HTML, #SCSS, #Javascript, #Photoshop",
    text: "Flinders University is a public research university based in Adelaide, South Australia, with a footprint extending across 11 locations in South Australia and the Northern Territory.",
    headPara: "flinders university",
  },
  {
    id: 17,
    color: "#1c2756",
    image: "/logo/peugeot.jpeg",
    image2: "/askgraphix.png",
    title: "Peugeot",
    role: "#HTML, #SCSS, #Javascript, #Photoshop, #Animation/effects",
    text: "Peugeot is a French brand of automobiles owned by Stellantis. The family business that preceded the current Peugeot companies was founded in 1810, with a steel foundry that soon started making hand tools and kitchen equipment, and then bicycles.",
    headPara: "Peugeot",
  },
  {
    id: 18,
    color: "#5d5955",
    image: "/logo/Schick-Logo.png",
    image2: "/askgraphix.png",
    title: "Schick",
    role: "#Animation,#facebook game aplication, #Email marketing",
    text: "Schick creates trusted tools to help you uncover your true self. Since 1921, Schick has been sharpening blades and craft in an effort to continuously create products that suit every single consumers’ distinct needs. ",
    headPara: "Schick",
  },
  {
    id: 19,
    color: "#5d5955",
    image: "/logo/klm.png",
    image2: "/work/klm.jpg",
    title: "KLM",
    role: "#Animation, #Email marketing, #Flash, #Photoshop, #Animation/effects",
    text: "KLM Royal Dutch Airlines, legally Koninklijke Luchtvaart Maatschappij N.V., is the flag carrier airline of the Netherlands. KLM is headquartered in Amstelveen, with its hub at nearby Amsterdam Airport Schiphol. It is part of the Air France–KLM group and a member of the SkyTeam airline alliance.",
    headPara: "KLM",
  },
  {
    id: 20,
    color: "#5d5955",
    image: "/logo/sta-logo.webp",
    image2: "/work/studyadelaide.jpeg",
    title: "Study Adelaide",
    role: "#HTML, #CSS, #Javascript, #Photoshop, #Prasentation",
    text: "StudyAdelaide is a destination marketing and student support agency for the international education sector, promoting Adelaide as a centre of education excellence.",
    headPara: "https://studyadelaide.com/",
  },
  {
    id: 21,
    color: "#3e2774",
    image: "/logo/Cadbury-Logo.png",
    image2: "/work/cadbury.jpg",
    title: "Cadbury",
    role: "#HTML, #SCSS, #Javascript, #XD, #Animation/effects",
    text: "Cadbury Dairy Milk chocolate is one of the major success stories for Cadbury and one of the world’s most famous chocolate brands.",
    headPara: "https://cadbury.one/",
  },
  {
    id: 22,
    color: "#13abb1",
    image: "/logo/bizgain-logo.png",
    image2: "/work/bizgain.jpg",
    title: "Bizgain",
    role: "#VUE-JS, #HTML, #SCSS, #Javascript",
    text: "Bizgain is an easy-to-use platform for small businesses to grow their businesses using digital marketing. Our AI-driven platform helps business owners to launch professional-looking ad campaigns which are efficient and optimized to deliver results.",
    headPara: "https://www.bizgain.in",
  },
  {
    id: 23,
    color: "#016db8",
    image: "/logo/bluescopelysaught.jpeg",
    image2: "/work/Lysaght_Singpore.jpg",
    title: "Bluescope lysaught",
    role: "#HTML, #SCSS, #Javascript, , #Invision/XD/Sketch, #Timeline chart, #standup meeting, #write down task as per due dates, #Create a To-Do List for Each Week or Each Day, #Overview of Jira Work Management",
    text: "Lysaght is a division of BlueScope in Australia and has been supplying steel building products for residential and commercial projects across Australia for over 150 years. Through this long history, and as part of Australia’s largest steelmaker, Lysaght is also known for the extensive technical expertise offered by our experienced staff through a network of 25 locations Australia-wide. Today, Lysaght manufactures an extensive range of finished steel building products, including roofing, walling, guttering, fencing, purlins and formwork. Lysaght manufactures iconic, industry-standard products such as CUSTOM ORB® corrugated roofing, KLIP-LOK concealed-fix roofing and BONDEK® composite steel formwork.",
    headPara: "https://www.lysaght.com/",
  },
];
export default workData;