import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import WorkCSS from "./../style/work.module.scss";
import workData from "./WorkData"; // Make sure WorkData is a default export from a data file

function Work() {
  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  const [showIconsOnly, setShowIconsOnly] = useState(true);
  const toggleView = () => {
    setShowIconsOnly((prev) => !prev);
  };

  const renderCard = (card, index) => {
    const formattedTitle = formatTitleForURL(card.title);
    return (
      <li className={WorkCSS.logoItem} key={index}>
        <Link to={`/work/${card.id}/${formattedTitle}`}>
          <span>
            <img src={card.image} alt={card.title} />
          </span>
        </Link>
      </li>
    );
  };

  return (
    <>
      <Helmet>
        <title>ASK GRAPHIX | Work</title>
        <meta
          name="description"
          content="Discover top-notch web development services tailored to your needs. From custom websites to e-commerce solutions, our expert team delivers responsive, SEO-friendly, and visually stunning digital experiences."
        />
      </Helmet>
      <div className={WorkCSS.aneesWork}>
        <div onClick={toggleView} className={WorkCSS.iconToggleView}>
          <img
            src={showIconsOnly ? "./icons/toggleIcon2.svg" : "./icons/toggleIcon1.svg"}
            alt="Toggle View"
          />
        </div>
        {!showIconsOnly ? (
          <div className={WorkCSS.container}>
            <ul className={WorkCSS.logoList}>{workData.map(renderCard)}</ul>
          </div>
        ) : (
          <div className={WorkCSS.detailPage1}>
            {workData.map((item, index) => (
              <React.Fragment key={index}>
                <div className={WorkCSS.detailPage1Item}>
                  <div className={WorkCSS.picPan}>
                    <img src={item.image} alt={item.title} className={WorkCSS.logo} />
                    <img src={item.image2} alt={item.title} className={WorkCSS.workPic}  />
                  </div>
                  <div className={WorkCSS.textPan}>
                    <h2>{item.title}</h2>
                    <h3>{item.headPara}</h3>
                    <div>
                      <div className={WorkCSS.strngText}>
                        <strong>{item.role}</strong>
                      </div>
                      <div className={WorkCSS.details}>
                        <h4>THE DETAILS</h4>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                {index !== workData.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </div>
        )}
        {!showIconsOnly && (
          <div className={WorkCSS.container}>
            <p className={WorkCSS.talePara}>
              All the above projects except (*Cadbury and Bizgain) are undertaken
              while I was associated with{" "}
              <a href={"https://kx.havas.com/"} target="_blank" rel="noreferrer">
                Havas.
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default Work;
