import React from "react";
import { Helmet } from "react-helmet";

function NoPage() {
  return (
    <>
    <Helmet>
        <title>ASK GRAPHIX | No Page 404</title>
        <meta
          name="description"
          content="Discover top-notch web development services tailored to your needs. From custom websites to e-commerce solutions, our expert team delivers responsive, SEO-friendly, and visually stunning digital experiences."
        />
      </Helmet>
      <div className="noPage">
      <h2 className="m-5 text-center">404</h2>
    </div>
    </>
  );
}

export default NoPage;
