import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import workData from "./WorkData";
import WorkDetailCSS from "./../style/workDetail.module.scss";
import { Helmet } from "react-helmet";

function WorkDetail() {
  const { workId } = useParams();
  const thisWork = workData.find((work) => work.id === parseInt(workId));
  const [enabled, setEnabled] = useState(true);

  if (!thisWork) {
    return <div>Work not found</div>;
  }

  const imageUrl = thisWork.image;

  const jsxWorkData = {
    backgroundImage: `url(${imageUrl})`,
  };
  const jsxInner = {
    backgroundColor: `${thisWork.color}`,
  };

  return (
    <>
      <Helmet>
        <title>ASK GRAPHIX | Work</title>
        <meta
          name="description"
          content="Discover top-notch web development services tailored to your needs. From custom websites to e-commerce solutions, our expert team delivers responsive, SEO-friendly, and visually stunning digital experiences."
        />
      </Helmet>

      <div className="workPan">
        <div className={enabled ? WorkDetailCSS.showWorkOn : WorkDetailCSS.showWorkOff}>
          <div className={WorkDetailCSS.showWorkData} style={jsxWorkData}>
            <div className={WorkDetailCSS.inner} style={jsxInner}>
              <h2 className={WorkDetailCSS.subTitle}>
                {thisWork.title}
                <Link to="/work" className={WorkDetailCSS.iconClose} onClick={() => setEnabled(!enabled)}>
                  <svg className={WorkDetailCSS.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className={WorkDetailCSS.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
                    <path className={WorkDetailCSS.checkmark__check} fill="none" d="M16 16 36 36 M36 16 16 36" />
                  </svg>
                </Link>
              </h2>
              <div className={WorkDetailCSS.breadCrumbs}>
                <Link to="/">Home</Link>
                <span> / </span>
                <Link to="/work">Work</Link> / {thisWork.title}
              </div>
              <h2 className={WorkDetailCSS.headPara}>{thisWork.headPara}</h2>
              <div className={WorkDetailCSS.workPicPanel}>
                <img src={thisWork.image2} alt={thisWork.title} />
              </div>
              <div className={WorkDetailCSS.usedSkills}>
                <strong>{thisWork.role}</strong>
              </div>
              <div className={WorkDetailCSS.detailPara}>
                <h3>THE DETAILS:</h3> <br /> {thisWork.text}
              </div>
              <div className={WorkDetailCSS.workDataTale}>
                <Link to="/">&lt;&lt; Back to Home</Link>
                <Link to="/work">&lt;&lt; Back to work</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkDetail;
