import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    let scrollFunction = function () {
      let scrollPosition = $(this).scrollLeft();
      if (scrollPosition === 0) {
        $(this).removeClass("scroll");
      } else {
        $(this).addClass("scroll");
      }
    };
    $(".heroCards").scroll(scrollFunction);
  }, []);

  return (
    <>
      <Helmet>
        <title>ASK GRAPHIX</title>
        <meta
          name="description"
          content="Education & Learning/Enterprise & IT/Financial Services/Healthcare & Lifesciences/Networks & Smart Devices/News, Media & Entertainment/Retail/Trading/Other,Top-notch web development for Education & Learning/Enterprise & IT/Financial Services/Healthcare & Lifesciences/Networks & Smart Devices/News, Media & Entertainment/Retail/Trading/various industries,Custom platforms/Custom websites, responsive, SEO-friendly, and visually stunning/visually impressive/visually appealing/visually engaging/visually striking"
        />
      </Helmet>
      <div className="home">
        <div className="homeContainer">
          <Menu cards={5} />
          <div className="midpan">
            <div className="heroCards" id="heroCards">
              <div className="heroCards__list">
                <div className="heroCards__item logo">
                  <Link to="/about">
                    a<span className="hide">nees pasha.</span>Sk
                  </Link>
                </div>
                <div className="heroCards__item about" id="card1">
                  <h1>
                    <Link to="/about">
                      I am Anees, a Frontend Developer and Coach with 20 years of experience in building cohesive teams and creating innovative products that foster connections. My passion lies in collaboration, designing playful user interfaces, and implementing comprehensive practices to ensure excellence.
                    </Link>
                  </h1>
                  <div className="scrollPage">
                    <div className="scrollPageInner">
                      <i>scroll</i>
                      <div className="arrowWrapper">
                        <div className="arrow">
                          <div className="arrow-body"></div>
                          <div className="down-arrow-head"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="card2"
                  className="heroCards__item bgOne flex-center"
                  style={{
                    backgroundImage: "url(/design-aerial-view-website.png)",
                  }}
                >
                  <div className="picPan">
                    <img src="/design-aerial-view-website.png" alt="design" />
                  </div>
                </div>
                <div className="heroCards__item">
                  <div className="textPan">
                    <h2>aSk Design!</h2>
                    <p>
                      Starting from the colors, fonts, layouts, and images to
                      successfully creating websites that are not only
                      aesthetically pleasing but also provide good user
                      experience. Web designing directly affects the visual
                      aspect of a website, which is necessary to communicate
                      ideas effectively.
                    </p>
                    <div className="cta">
                      <Link to="/work" className="cta">
                        <span>My works</span>
                        <div className="arroPan">
                          <div className="arrow"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id="card3"
                  className="heroCards__item bgOne flex-center"
                  style={{
                    backgroundImage: "url(/program-coding-screen.jpg)",
                  }}
                >
                  <div className="picPan">
                    <img src="/program-coding-screen.jpg" alt="development" />
                  </div>
                </div>
                <div className="heroCards__item">
                  <div className="textPan">
                    <h2>aSk Web Development!</h2>
                    <p>
                      Writing well-designed, testable, and efficient code by
                      following best software development practices. Creating
                      attractive website layouts and flawless user interfaces.
                      Integrating data from different services, and gathering
                      and refining requirements based on technical feasibilities.
                    </p>
                    <div className="cta">
                      <Link to="/work">
                        <span>My works</span>
                        <div className="arroPan">
                          <div className="arrow"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="heroCards__item bgOne flex-center"
                  style={{
                    backgroundImage: "url(/searching-engine-optimizing.jpg)",
                  }}
                >
                  <div className="picPan">
                    <img src="/searching-engine-optimizing.jpg" alt="SEO" />
                  </div>
                </div>
                <div id="card4" className="heroCards__item">
                  <div className="textPan">
                    <h2>aSk SEO!</h2>
                    <p>
                      Supporting clients through Search Engine Optimization to
                      improve the quality and quantity of website traffic from
                      search engines. SEO helps in targeting unpaid traffic
                      rather than direct or paid traffic.
                    </p>
                    <div className="cta">
                      <Link to="/work" className="cta">
                        <span>My works</span>
                        <div className="arroPan">
                          <div className="arrow"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="heroCards__item bgOne flex-center"
                  style={{
                    backgroundImage:
                      "url(/online-marketing-commercial-connection-technology.jpg)",
                  }}
                >
                  <div className="picPan">
                    <img
                      src="/online-marketing-commercial-connection-technology.jpg"
                      alt="The role of Digital Marketing!"
                    />
                  </div>
                </div>
                <div id="card5" className="heroCards__item">
                  <div className="textPan">
                    <h2>aSk Marketing!</h2>
                    <p>
                      Providing digital marketing services broadly, including
                      Pay-per-Click, Social Media Marketing, Content Marketing,
                      Email Marketing, Mobile Marketing, Marketing Analytics, and
                      Affiliate Marketing.
                    </p>
                    <div className="cta">
                      <Link to="/work" className="cta">
                        <span>My works</span>
                        <div className="arroPan">
                          <div className="arrow"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;