import React from "react";
import styles from "./../style/about.module.scss";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

function About() {
  return (
    <>
    <Helmet>
        <title>ASK GRAPHIX | About Us</title>
        <meta
          name="description"
          content="Discover top-notch web development services tailored to your needs. From custom websites to e-commerce solutions, our expert team delivers responsive, SEO-friendly, and visually stunning digital experiences."
        />
      </Helmet>
    <div className={styles.aboutAnees}>
      <div className={`${styles.container} container`}>
        <div className={styles.flex}>
          <div className={styles.aboutTextPan}>
            <div className={styles.textInner}>
              <h1>About Anees</h1>
              <section>
                <p>I am Anees, a Frontend Developer and Coach with 20 years of experience in building cohesive teams and creating innovative products that foster connections. My passion lies in collaboration, designing playful user interfaces, and implementing comprehensive practices to ensure excellence.</p>
                <p>Throughout my extensive career as a UI/UX developer, I have embraced numerous new ideas and practices, continually evolving my skill set to meet the dynamic demands of the industry. I am confident that I can significantly contribute to your business needs and goals by applying sound IT principles.</p>
                <p>With over two decades of professional experience, I have mastered various aspects of web development and user experience, including user metrics analysis, design principles, wireframing, information architecture, accessibility, usability, CSS, HTML, JavaScript, and SEO. I have working knowledge of React.js and Vue.js at a novice level, and I am proficient in WordPress development. My expertise extends to human-computer interaction, usability testing, and prototyping.</p>
                <p>I pride myself on my adaptability and hands-on development experience, coupled with an in-depth understanding of the journey through which I have acquired vast knowledge and skills. I excel in collaborating with designers to analyze and refine wireframes and mockups prior to development, ensuring enhanced usability and a positive consumer experience. I possess a thorough knowledge of user-centered design methodology and can lead overall Web UI/UX development and usability strategies, balancing user needs with business goals.</p>
                <p>My extensive experience and unique skill set distinguish me as a highly qualified professional. I am eager to learn more about your IT department’s plans and to contribute to your organization’s success. I am confident that I can not only help achieve your overall goals but also gain valuable insights that will further enhance my expertise.</p>
                <p>-----</p>
                <p>Thank you for your time and consideration.</p>
                <span>
                Have a question, a project idea, or just want to say hello? We're always excited to connect with new people.<NavLink className={styles.abLink} to="/Myform">Reach out <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" color="#ffffff" fill="none">
    <path d="M18 6C17.9531 4.44655 17.7797 3.51998 17.1377 2.87868C16.2581 2 14.8423 2 12.0108 2H8.0065C5.17501 2 3.75926 2 2.87963 2.87868C2 3.75736 2 5.17157 2 8V16C2 18.8284 2 20.2426 2.87963 21.1213C3.75926 22 5.17501 22 8.0065 22H12.0108C14.8423 22 16.2581 22 17.1377 21.1213C17.7797 20.48 17.9531 19.5535 18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20.2419 11.7419L21.419 10.5648C21.6894 10.2944 21.8246 10.1592 21.8969 10.0134C22.0344 9.73584 22.0344 9.41003 21.8969 9.13252C21.8246 8.98666 21.6894 8.85145 21.419 8.58104C21.1485 8.31062 21.0133 8.17542 20.8675 8.10314C20.59 7.96562 20.2642 7.96562 19.9866 8.10314C19.8408 8.17542 19.7056 8.31062 19.4352 8.58104L18.2581 9.7581M20.2419 11.7419L14.9757 17.0081L12 18L12.9919 15.0243L18.2581 9.7581M20.2419 11.7419L18.2581 9.7581" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path opacity="0.4" d="M5 19H6L7.25 16.5L8.5 19H9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path opacity="0.4" d="M6 6H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path opacity="0.4" d="M6 10H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg></NavLink>.
                </span>
                
              </section>
            </div>
          </div>
          <div className={styles.aboutPicPan}>
            <img src="/anees-IMG_4.jpeg" alt="SK Anees pasha" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;
